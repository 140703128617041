<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <div class="container-fluid">
          <!-- Default box -->
          <section class="content-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="mb-0">Asignaciones documentos</h5>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/Hse">Hse</router-link>
                    </li>
                    <li class="breadcrumb-item active">
                      Asignaciones documentos
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section class="content">
            <div class="container-fluid">
              <div class="card">
                <div class="card-header pt-2 pb-2">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn btn-info"
                          @click="back()"
                        >
                          <i class="fas fa-sync-alt"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary"
                          v-if="
                            $store.getters.can(
                              'hse.asignacionDocumentos.create'
                            )
                          "
                          @click="create()"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <table
                    style="font-size: 0.85em"
                    class="table table-bordered table-striped table-hover table-sm text-nowrap"
                  >
                    <thead class="text-center">
                      <tr>
                        <th>
                          Id
                          <input
                            type="number"
                            class="form-control form-control-sm p-0"
                            v-model="filters.asignacion_id"
                            label="id"
                            @input="getIndex()"
                          />
                        </th>
                        <th>
                          Tipo Solicitud
                          <select
                            v-model="filters.tipo_solicitud"
                            class="form-control form-control-sm"
                            @input="getIndex()"
                          >
                            <option value="">Seleccionar...</option>
                            <option value="1">Vehículos</option>
                            <option value="2">Conductores</option>
                          </select>
                        </th>
                        <th>
                          Tipo Vehículo
                          <v-select
                            class="form-control form-control-sm p-0"
                            v-model="tipo_vh"
                            placeholder="Tipo Vehículos"
                            label="nombre"
                            :options="listsForms.tipos_vehiculos"
                            @input="selectTipoVh()"
                          >
                          </v-select>
                        </th>
                        <th>
                          Paquete Documento
                          <v-select
                            class="form-control form-control-sm p-0"
                            v-model="paquete"
                            placeholder="Paquetes Documentos"
                            label="nombre"
                            :options="listsForms.paquetes"
                            @input="selectPaquete()"
                          >
                          </v-select>
                        </th>
                        <th
                          v-if="
                            $store.getters.can(
                              'hse.asignacionDocumentos.edit'
                            ) &&
                            $store.getters.can(
                              'hse.asignacionDocumentos.delete'
                            )
                          "
                        >
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="asignacion in asignaciones.data"
                        :key="asignacion.data"
                      >
                        <td>{{ asignacion.id }}</td>
                        <td v-if="asignacion.tipo_solicitud == 1">Vehículos</td>
                        <td v-else-if="asignacion.tipo_solicitud == 2">
                          Conductores
                        </td>
                        <td v-else></td>

                        <td v-if="asignacion.tipos_vh">
                          {{ asignacion.tipos_vh.nombre }}
                        </td>
                        <td v-else></td>
                        <td>{{ asignacion.paquetes.nombre }}</td>

                        <td
                          style="width: 50px"
                          v-if="
                            $store.getters.can(
                              'hse.asignacionDocumentos.edit'
                            ) &&
                            $store.getters.can(
                              'hse.asignacionDocumentos.delete'
                            )
                          "
                        >
                          <div class="btn-group float-right">
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              v-if="
                                $store.getters.can(
                                  'hse.asignacionDocumentos.edit'
                                )
                              "
                              @click="edit(asignacion)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-danger"
                              v-if="
                                $store.getters.can(
                                  'hse.asignacionDocumentos.delete'
                                )
                              "
                              @click="destroy(asignacion.id)"
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <pagination
                  :data="asignaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "AsignacionDocumentosIndex",
  components: { vSelect, pagination, Loading },
  data() {
    return {
      cargando: false,
      filters: {
        tipo_vh_id: null,
        paquete_id: null,
        tipo_solicitud: null,
      },
      solicitud: null,
      paquete: null,
      tipo_vh: null,
      asignaciones: {},
      listsForms: {
        paquetes: [],
        tipos_vehiculos: [],
      },
    };
  },

  methods: {
    create() {
      return this.$router.push({
        name: "/Hse/AsignacionDocumentosForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(asignacion) {
      return this.$router.push({
        name: "/Hse/AsignacionDocumentosForm",
        params: { accion: "Editar", data_edit: asignacion, id: asignacion.id },
      });
    },

    back() {
      this.clearFilters();
      this.getIndex();
    },

    //obtener datos
    async getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("api/hse/AsignacionDocumentos?page=" + page, {
          params: await this.filters,
        })
        .then((response) => {
          this.cargando = false;
          this.asignaciones = response.data;
        });
    },

    destroy(id) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .delete("api/hse/AsignacionDocumentos/" + id)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    getTipoVh() {
      axios.get("api/hse/HseSolicitudesVehiculos/listaTipoVh").then((response) => {
        this.listsForms.tipos_vehiculos = response.data;
      });
    },

    getPaquetes() {
      axios.get("/api/admin/paquetes/lista").then((response) => {
        this.listsForms.paquetes = response.data;
      });
    },

    //Seleccionar solo ids
    selectTipoVh() {
      this.filters.tipo_vh_id = null;
      if (this.tipo_vh) {
        this.filters.tipo_vh_id = this.tipo_vh.id;
      }
      this.getIndex();
    },

    selectPaquete() {
      this.filters.paquete_id = null;
      if (this.paquete) {
        this.filters.paquete_id = this.paquete.id;
      }
      this.getIndex();
    },

    //Limpiar filtros
    clearFilters() {
      this.filters = {
        tipo_vh_id: null,
        paquete_id: null,
        tipo_solicitud: null,
      };
      this.paquete = null;
      this.tipo_vh = null;
    },
  },

  mounted() {
    this.getIndex();
    this.getPaquetes();
    this.getTipoVh();
  },
};
</script>

